<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-if="isNewPlan">
        <c-card class="cardClassDetailForm" title="요청된 작업이 없습니다.">
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-if="!isNewPlan">
        <c-card class="cardClassDetailForm" title="정비요청 상세">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-if="param.woRequestId && param.woWorkPlanStepCd=='WWPSC00000'" label="반려" :editable="editable" icon="delete_forever" @btnClicked="companionPlan" />
              <c-btn v-if="param.woRequestId && param.woWorkPlanStepCd=='WWPSC00000'" label="접수" :editable="editable" icon="save" @btnClicked="savePlan" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-equip
                :required="true"
                :editable="false"
                label="설비Tag"
                name="equipmentCd"
                v-model="data.equipmentCd">
              </c-equip>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-func-location 
                type="edit" 
                :plantCd="data.plantCd" 
                :editable="false"
                label="기능위치" 
                name="funcLocationCd" 
                v-model="data.funcLocationCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-cost-center
                type="edit" 
                :plantCd="data.plantCd" 
                :editable="false"
                label="코스트센터" 
                name="costCenterCd" 
                v-model="data.costCenterCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-select
                :required="true"
                :editable="false"
                codeGroupCd="WO_WORK_TYPE_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="woWorkTypeCd"
                label="작업유형"
                v-model="data.woWorkTypeCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-text
                :required="true"
                :editable="false"
                label="작업명"
                name="woRequestName"
                v-model="data.woRequestName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                :required="true"
                :editable="false"
                default="today"
                label="요청일"
                name="woRequestDt"
                v-model="data.woRequestDt"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-dept type="edit" :editable="false" label="정비요청 부서" :required="true" name="deptCd" v-model="data.deptCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-field
                :required="true"
                :editable="false"
                :data="data"
                type="user"
                label="정비요청인"
                name="userId"
                v-model="data.userId" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                :required="true"
                :editable="false"
                name="plantCd"
                v-model="data.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
              <c-checkbox
                :editable="false"
                :isFlag="true"
                label="긴급작업"
                name="emergencyFlag"
                v-model="data.emergencyFlag"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-text
                :editable="false"
                label="긴급작업 사유"
                name="emergencyReason"
                v-model="data.emergencyReason">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
              <c-text
                :editable="false"
                label="MOC번호"
                name="mocId"
                v-model="data.mocId">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                :editable="false"
                label="MOC 명칭"
                name="mocName"
                v-model="data.mocName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-text
                :editable="editable"
                :disabled="disabled"
                label="반려사유"
                name="refusalReason"
                v-model="data.refusalReason">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="param.woWorkPlanStepCd=='WWPSC99998'">
              <c-text
                :editable="false"
                label="요청 반려자"
                name="chgUserName"
                v-model="data.chgUserName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="param.woWorkPlanStepCd=='WWPSC99998'">
              <c-text
                :editable="false"
                label="요청 반려일"
                name="chgDtStr"
                v-model="data.chgDtStr">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <c-textarea
                :editable="false"
                :rows="7"
                label="정비요청 세부사항"
                name="remark"
                v-model="data.remark">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-table
                ref="grid"
                title="정비오더 발행부서"
                :columns="grid.columns"
                :data="grid.data"
                :gridHeight="grid.height"
                :editable="false"
                :hideBottom="true"
                :isExcelDown="false"
                :filtering="false"
                :isFullScreen="false"
                :columnSetting="false"
                selection="multiple"
                rowKey="deptCd"
              >
              </c-table>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:25px !important;" v-if="!isNewPlan">
        <c-upload 
          :attachInfo="attachInfo"
          :editable="false"
          label="첨부파일">
        </c-upload>
      </div>
    </div>
    <!-- <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">반려 사유 입력</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="refusalReason" autofocus @keyup.enter="setReturnReason" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="취소" @click="cancelReturnReason" />
          <q-btn flat label="확인" @click="setReturnReason" />
        </q-card-actions>
      </q-card>
    </q-dialog> -->
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-order-plan00',
  props: {
    param: {
      type: Object,
      default: () => ({
        workPlanId: '',
        woRequestId: '',
        plantCd: null,
        woWorkPlanStepCd: '',
        copyFlag: '',
        firstCopy: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      prompt: false,
      popupOptions: {
        isFull: false,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'WO_WORK_REQUEST',
        taskKey: '',
      },
      editable: true,
      detailUrl: '',
      deptListUrl: '',
      saveUrl: '',
      deleteUrl: '',
      rejectUrl: '',
      isNewPlan: false,
      refusalReason: '',
      data: {
        plantCd: null,  // 사업장코드
        woRequestId: '',  // 정비요청 일련번호
        woRequestName: '',  // 작업명
        deptCd: '',  // 발행 부서
        userId: '',  // 발행인
        woRequestDt: '',  // 발행일
        woWorkTypeCd: null,  // 작업 유형코드
        equipmentCd: '',  // 대상 설비
        funcLocationCd: '',  // 기능위치
        remark: '',  // 정비요청 세부사항
        woWorkReqStepCd: '',  // 정비요청 상태(작성중)
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        emergencyFlag: '',
        emergencyReason: '',
        mocId: '',
        mocName: '',
        costCenterCd: '',
        refusalReason: '',
        companionFlag: 'N',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
        height: '170px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.data.woWorkReqStepCd !== 'WWRSC00003'
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.wod.workreq.get.url;
      this.deptListUrl = selectConfig.wod.workreq.depts.url;
      this.saveUrl = transactionConfig.wod.workorder.plan.start.url;
      this.deleteUrl = transactionConfig.wod.workorder.plan.cancel.url;
      this.rejectUrl = transactionConfig.wod.workorder.plan.cancel.url;
      this.getDetail();
    },
    getDetail() {
      if (this.param.woRequestId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {woRequestId: this.param.woRequestId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.$set(this.attachInfo, 'taskKey', this.param.woRequestId)
          this.getDeptList();
        },);
        this.isNewPlan = false;
      } else {
        this.isNewPlan = true;
      }
    },
    getDeptList() {
      this.$http.url = this.deptListUrl;
      this.$http.param = {woRequestId: this.param.woRequestId};
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    savePlan() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '정비요청을 접수하시겠습니까?',
        // TODO : 작업계획을 작성하시겠습니까?
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.saveUrl;
          this.$http.type = 'PUT';
          this.$http.param = {
            woWorkPlanStepCd: 'WWPSC00001', // 작업계획중
            woWorkReqStepCd: 'WWRSC00010', // 접수승인
            regUserId: this.$store.getters.user.userId,
            chgUserId: this.$store.getters.user.userId,
            workPlanId: this.param.workPlanId,
            woRequestId: this.param.woRequestId,
          };
          this.$http.request(() => {
            this.param.woWorkPlanStepCd = 'WWPSC00001';
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '정비오더를 작성하시기 바랍니다.', /* 결재가 반려 처리되었습니다. */
              type: 'success', // success / info / warning / error
            });
            
            this.$emit('emitStep', {
              name: 'stepBystep',
              param: {
                stepCd: 'WSC0000001',
                woWorkPlanStepCd: 'WWPSC00001',
              }
            })
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // addReturnReason() {
    //   this.prompt = true;
    // },
    // setReturnReason() {
    //   this.prompt = false;
    //   this.companionPlan();
    // },
    // cancelReturnReason() {
    //   this.refusalReason = '';
    //   this.prompt = false;
    // },
    companionPlan() {
      if (!this.data.refusalReason) {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '반려사유를 입력하세요.', /* 반려사유를 입력하세요. */
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '정비요청을 반려하시겠습니까?',
        // TODO : 정비요청을 취소하시겠습니까?\r\n작업취소 상태로 변경됩니다.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.rejectUrl;
          this.$http.type = 'PUT';
          this.$http.param = {
            woWorkReqStepCd: 'WWRSC00009', // 반려
            chgUserId: this.$store.getters.user.userId,
            workPlanId: this.param.workPlanId,
            woRequestId: this.param.woRequestId,
            sysApprovalRequestId: this.data.sysApprovalRequestId,
            refusalReason: this.data.refusalReason,
          };
          this.$http.request(() => {
            this.param.woWorkReqStepCd = 'WWRSC00009';
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '정비요청이 반려되었습니다.', /* 결재가 반려 처리되었습니다. */
              type: 'success', // success / info / warning / error
            });
            
            this.$emit('emitStep', {
              name: 'closePopup'
            })
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>